$nftWidth: 500px;
.wrapper {
  width: 100%;
  display: flex;
  gap: 4rem;
  letter-spacing: 0.04em;

  p {
    text-align: center;
    width: 100%;
  }
  .previewWrapper {
    width: $nftWidth;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ffffff;
    padding: 1rem;

    .previewBox {
      width: 100%;
      position: relative;
      overflow: hidden;

      &::before {
        display: block;
        content: "";
        padding-bottom: 100%;
      }
    }

    .baseImageBox {
      display: none;
    }

    canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .detailsWrapper {
    padding: 1rem 0;
    width: fit-content;
    display: flex;
    flex-direction: column;

    .inputGroup {
      width: 100%;
      display: flex;
      gap: 2rem;
      justify-content: flex-start;
      margin-bottom: 2rem;

      select {
        width: 100%;
      }
    }
  }

  .groupContent {
    margin-top: 1rem;
  }
}

.loading {
  font-size: 1.5rem;
  text-transform: lowercase;
}

button {
  font-size: 15px;
  border: 1px solid #fff;
  background: none;
  color: #fff;
  text-transform: uppercase;
  padding: 0.6rem 0;
  min-width: 130px;
  font-family: inherit;
  opacity: 0.5;
  letter-spacing: inherit;

  &:hover:not(:disabled),
  &:focus-within {
    opacity: 1;
    cursor: pointer;
  }
}
.fileInput {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;

  & ~ label {
    font-size: 15px;
    text-transform: uppercase;
    padding: 0.6rem 3.5rem;
    border: 1px solid #ffffff;
    opacity: 0.5;
    width: 100%;
  }

  &:not(:disabled) {
    cursor: pointer;
    & ~ label:hover {
      opacity: 1;
    }

    &:focus-within {
      & ~ label {
        opacity: 1;
      }
    }
  }
}

.select {
  margin: 0;
  color: #fff;
  opacity: 0.5;
  width: 100%;
  text-align: center;

  &:focus-within,
  &:hover {
    opacity: 1;
  }

  [class*="control"] {
    font-size: 15px;
    text-transform: uppercase;
    padding: 0.6rem 3.5rem;
    border: 1px solid #ffffff;
    cursor: pointer;
    letter-spacing: inherit;
    width: 100%;
    background: none;
    border-radius: 0;
    box-shadow: none;
  }

  [class*="option"] {
    background-color: #000000;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;

    &:hover,
    &:focus-within {
      color: #ffffff;
    }
  }

  [class*="value-container"] {
    padding: 0;
  }

  [class*="single-value"] {
    color: #ffffff;
  }

  [class*="menu-list"] {
    padding: 0;
  }
}
