.App {
  max-width: 1000px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  position: fixed;
  top: 2rem;
  left: 2rem;
}