@font-face {
  font-family: 'GroteskMono';
  font-weight: 400;
  src: url('./fonts/FKGroteskMono-Regular.otf');
}
@font-face {
  font-family: 'Tactic';
  font-weight: 400;
  src: url('./fonts/Tactic-Regular.otf');
}

body {
  margin: 0;
  font-family: 'GroteskMono', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: white;
}